import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { HTMLAttributes, memo, useCallback, useState } from 'react';

import { ContextMenu } from '~/src/components/app/ContextMenu';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useAuth } from '~/src/hooks/Account/useAuth';

import { AvatarMenu } from '../AvatarMenu/AvatarMenu';

export type AvatarProps = HTMLAttributes<HTMLDivElement>

export const Avatar = memo((props: AvatarProps) => {
  const { name } = useAuth();
  const shortName = name ? name.substring(0, 2).toUpperCase() : undefined;
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);

  const clickProfile = useCallback(() => {
    AmplitudeTaxonomyClient.clickProfile();
  }, []);

  return (
    <ContextMenu
      open={isOpen}
      menu={
        <AvatarMenu
          onClickItem={() => setOpen(false)}
        />
      }
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div
        { ...props }
        className={classNames(
          'Avatar',
          props.className
        )}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${theme.app.colors['primary-color-02']};
          width: 48px;
          height: 48px;
          border-radius: 50%;
        `}
        onClick={clickProfile}
      >
        <Typography
          variant="button2"
          css={css`
            color: ${theme.app.colors['neutral-color-10']};
          `}
        >
          { shortName }
        </Typography>
      </div>
    </ContextMenu>
  );
});

Avatar.displayName = 'Avatar';
