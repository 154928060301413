import { useMediaQuery as useMuiMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { LegacyDefaultTheme } from '../../../themes/legacyDefault';

type Breakpoint = keyof typeof LegacyDefaultTheme.breakpoints;

export const useMediaQuery = (breakpoint: Breakpoint) => {
  const theme = useTheme();
  const upIs = useMuiMediaQuery(theme.breakpoints.up(breakpoint));
  const onlyIs = useMuiMediaQuery(theme.breakpoints.only(breakpoint));
  const downIs = useMuiMediaQuery(theme.breakpoints.down(breakpoint));

  const result = useMemo(() => {
    return {
      up: {
        mq: theme.breakpoints.up(breakpoint),
        is: upIs,
      },
      only: {
        mq: theme.breakpoints.only(breakpoint),
        is: onlyIs,
      },
      down: {
        mq: theme.breakpoints.down(breakpoint),
        is: downIs,
      },
    };
  }, [
    theme,
    upIs,
    onlyIs,
    downIs,
    breakpoint,
  ]);

  return result;
};
