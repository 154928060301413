import { Fragment } from 'react';

import { usePlan } from '~/src/hooks/Account';

import { SwitchCaseByUserTypeProps } from './SwitchCaseByUserType.types';

/**
 * 사용자의 구독 정보에 맞는 UI가 보여지도록 구현할 때 사용합니다.
 *
 * userType 프로퍼티에 해당 UI가 보여질 권한을 모두 기입하시면 해당 권한과 일치하다면 UI가 노출됩니다.
 * no-user 값을 입력하시면 비회원에게만 노출 됩니다.
 */
export const SwitchCaseByUserType = ({
  cases = [],
  fallback,
  children,
}: SwitchCaseByUserTypeProps) => {
  const { userPlan } = usePlan();

  // 검색할 유저의 타입을 배열 형태로 정리합니다. (문자열이 입력 된 경우에는 탐색 코드의 통일을 위해 아이템 한 개의 배열로 변경합니다.)
  const validCases = typeof cases === 'string' ? [cases] : cases;

  if (userPlan === undefined) {
    return (
      <Fragment>
        { fallback }
      </Fragment>
    );
  }

  // 플랜 정보가 일치할 때만 노출됩니다.
  if (userPlan && validCases.includes(userPlan)) {
    return (
      <Fragment>
        { children }
      </Fragment>
    );
  }

  return (
    <Fragment />
  );
};
