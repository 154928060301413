import { css } from '@emotion/react';

import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';

import { MobileHeader } from '../../app/MobileHeader';
import { Header } from '../../business/Header';

const HEADER_TYPE_DESKTOP = 'desktop';
const HEADER_TYPE_MOBILE = 'mobile';

export const ResponsibleHeader = () => {
  const tablet = useMediaQuery('laptop');
  const headerType = tablet.down.is ? HEADER_TYPE_MOBILE : HEADER_TYPE_DESKTOP;

  return (
    <div
      className="ResponsibleHeader"
      css={css`
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
      `}
    >
      <Header
        css={headerType === HEADER_TYPE_DESKTOP ? undefined : hide}
      />
      <MobileHeader
        css={headerType === HEADER_TYPE_MOBILE ? undefined : hide}
      />
    </div>
  );
};

const hide = css`
  display: none;
`;
