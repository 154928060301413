import { css } from '@emotion/react';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '~/src/components/app/Button/v2';
import { USER_PLAN_YEARLY } from '~/src/constants/UserPlan';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';

import { useAccountDialog } from '../../../Dialogs';
import { SwitchCaseByUserType } from '../../../SwitchCaseByUserType';
import { Avatar } from '../Avatar';
import { HeaderColumn } from '../HeaderColumn';
import { MyWorkLink } from '../MyWorkLink';
import { YearlyBadge } from '../YearlyBadge';

export const AuthenticationColumn = () => {
  const router = useRouter();
  // Hydration error 방지를 위해 마운트 여부를 알 수 있는 state
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { t } = useTranslation('common');

  const {
    show: showAccountDialog,
  } = useAccountDialog();

  const onClickYearlyBadge = useCallback(() => {
    AmplitudeTaxonomyClient.clickMasterBadge();
  }, []);

  const clickLoginButton = useCallback(() => {
    const isHome = router.asPath === '/';

    // memo: home 화면에서는 Redirect 기록을 남기지 않도록 수정함.
    showAccountDialog(isHome ? undefined : router.asPath);
    AmplitudeTaxonomyClient.clickLoginButton();
  }, [
    router,
    showAccountDialog,
  ]);

  const clickSignupButton = useCallback(() => {
    showAccountDialog(router.asPath);
    AmplitudeTaxonomyClient.clickSignUpButton();
  }, [
    router,
    showAccountDialog,
  ]);

  const desktop = useMediaQuery('desktop');

  // 로그인 세션 여부를 판단하기 위한 토큰
  const accessToken = getCookie('access-token');

  return (
    <>
      {
        mounted && accessToken ?
          <HeaderColumn>
            <SwitchCaseByUserType cases={USER_PLAN_YEARLY}>
              {
                desktop.down.is === false &&
                <YearlyBadge
                  css={css`
                    margin-right: 24px;
                  `}
                  onClick={onClickYearlyBadge}
                />
              }
            </SwitchCaseByUserType>
            <MyWorkLink />
            <Avatar />
          </HeaderColumn>
          :
          <HeaderColumn>
            <Button
              variant="outlined"
              size="medium"
              css={css`
              margin-right: 8px;
            `}
              onClick={clickLoginButton}
            >
              { t('common:header.login') }
            </Button>
            <Button
              size="medium"
              color="gradient"
              onClick={clickSignupButton}
            >
              { t('common:header.sign-up') }
            </Button>
          </HeaderColumn>
      }
    </>
  );
};
